<template>
	<section class="text-center" :style="{backgroundColor: bgColor}">
		<div class="section-content-wrapper">
			<div class="text-image-wrapper">
				<article class="text-container" :style="{ color: textColor }">
					<h2>{{ heading }}</h2>
					<p v-html="paragraph"></p>
					<a
						:v-if="button"
						:href="buttonItem ? buttonItem.url : null"
						:class="{ hide: !button }"
						:style="{ color: textColor, border: '2px solid' + textColor }"
					>
						{{ buttonItem ? buttonItem.title : null }}
					</a>
				</article>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'TextCenter',
	props: {
		heading: {
			type: String,
			required: true,
		},
		paragraph: {
			type: String,
			required: true,
		},
		button: {
			type: Boolean,
			required: true,
		},
		buttonItem: {
			type: Object,
			required: false,
			default: function () {
				return {
					url: '',
					title: '',
				}
			},
		},
		bgColor: {
			type: String,
			required: true,
		},
		textColor: {
			type: String,
			required: true,
		},
	},
}
</script>
<style scoped lang="scss">
.text-center {
	background-color: $color-grey-dark;
	.section-content-wrapper {
		flex-direction: column;
		.text-image-wrapper {
			.text-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 0 21%;
				align-items: center;
				a {
					@include paragraph-small;

					font-family: $secondary-font-sbold;
					line-height: normal;
					text-transform: uppercase;
					text-decoration: none;
					border-radius: 24px;
					padding: 16px 56px;
					margin-top: 32px;
					width: fit-content;

					&.hide {
						display: none;
					}
				}

				p {
					width: 100%;
					margin-top: 0;
					line-height: 26px;
					text-align: center;
				}

				h2 {
					text-transform: uppercase;
					margin-bottom: 30px;
					margin-top: 0px;

					@include max-width(medium) {
						margin-bottom: 10px;
					}
				}
			}

			.img-container {
				@include aspect-ratio(0.9, 1);

				max-height: 500px;

				@include max-width(medium) {
					max-width: 570px;
				}

				&.wide {
					@include aspect-ratio(1.14, 1);
					max-width: 570px;
					max-height: 500px;
				}
			}

			@include max-width(medium) {
				grid-template-columns: repeat(1, 1fr);

				&.left {
					.text-container {
						margin-right: 0;
						margin-left: 0;

						p {
							margin-top: 0;
						}

						h2 {
							margin-top: 0;
						}
					}
				}
				.text-container {
					margin-right: 0;
					margin-left: 0;

					p {
						margin-top: 0;
					}

					h2 {
						margin-top: 0;
					}
				}

				.img-container {
					grid-area: 1 / 1 / 1 / 1;
					margin-bottom: 30px;

					@include max-width(medium) {
						margin-bottom: 35px;
					}
				}
			}
		}

		&.wide {
			@include default-padding-sides;

			padding-right: 0 !important;

			@include max-width(medium) {
				padding-left: 0 !important;
			}

			.text-image-wrapper {
				grid-template-columns: 4fr 3fr;

				@include max-width(medium) {
					grid-template-columns: 1fr;
				}
			}
			.text-container {
				margin-left: 0;
			}
		}
	}
}
</style>
